import { globalHistory } from '@reach/router';
import React, { useEffect, useRef, useState } from 'react';
import { getIcon } from '../../molecules/CallToAction/buttonHierarchy';
import { QuestionContainer } from './ShopPerformanceStyles';

const Questionnaire = ({
  ignoreLeave = false,
  onAnswerSelect,
  onGoBack,
  questions,
  step,
}) => {
  const activeStep = useRef(1);
  const question = questions[activeStep.current - 1];
  const questionContainer = useRef(null);
  const [questionClassName, setQuestionClassName] = useState('');

  useEffect(() => {
    if (step === activeStep.current) {
      return;
    }

    const exitAnimationClass =
      step > activeStep.current ? 'transition-out-next' : 'transition-out-back';
    const enterAnimationClass =
      step > activeStep.current ? 'transition-in-next' : 'transition-in-back';

    questionContainer.current.addEventListener(
      'animationend',
      () => {
        activeStep.current = step;
        setQuestionClassName(enterAnimationClass);
      },
      { once: true }
    );

    setQuestionClassName(exitAnimationClass);
  }, [step]);

  useEffect(() => {
    const shopPerformancePathname = '/shop-performance-questions';

    const handleLeave = () => {
      if (!ignoreLeave && window.dataLayer) {
        window.dataLayer.push?.({
          event: 'shop-performance-questions-unload',
          currentQuestion: activeStep.current,
        });
      }
    };

    const unlisten = globalHistory.listen(({ action, location }) => {
      if (action === 'PUSH' && location.pathname !== shopPerformancePathname) {
        handleLeave();
      }
    });

    window.addEventListener('beforeunload', handleLeave);

    return () => {
      window.removeEventListener('beforeunload', handleLeave);
      unlisten();
    };
  }, []);

  return (
    <QuestionContainer ref={questionContainer} className={questionClassName}>
      <div className="question-content">
        <h1>{`Question ${activeStep.current}`}</h1>
        <p className="question-title">{question.label}</p>
        <p className="question-instructions">
          <em>Select the most accurate answer</em>
        </p>
        <div className="question-answers">
          {Object.keys(question.answers).map(key => {
            const answer = question.answers[key];

            return (
              <button
                key={key}
                className="question-answer"
                type="button"
                name={key}
                onClick={() => onAnswerSelect(key)}
              >
                {answer.label}
              </button>
            );
          })}
        </div>
        {activeStep.current !== 1 ? (
          <div className="back-button-container">
            <button
              className="back-button"
              type="button"
              name="back"
              onClick={() => onGoBack()}
            >
              {getIcon('left')}
              <span className="back-button-label">Go Back</span>
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
    </QuestionContainer>
  );
};

export default Questionnaire;
